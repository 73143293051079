import React from "react"
import styled, { css } from "styled-components"

const ScaledElement = ({
  children,
  scaleHeight,
  scaleWidth,
  align,
  justify,
  fullWidth,
  fullHeight,
}) => {
  const computeScale = () => {
    const perc = ((scaleHeight / scaleWidth) * 100).toFixed(3)
    return perc + "%"
  }

  return (
    <Placeholder
      ratio={computeScale}
      scaleHeight={scaleHeight}
      scaleWidth={scaleWidth}
      align={align}
      justify={justify}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
    >
      {children}
    </Placeholder>
  )
}

const Placeholder = styled.div`
  display: flex;
  align-items: ${(p) => (p.align ? p.align : "center")};
  justify-content: ${(p) => (p.justify ? p.justify : "center")};
  position: relative;

  &:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: ${(props) => (props.ratio ? props.ratio : "50%")};
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  & > * {
    z-index: 1;
  }

  ${(p) =>
    p.fullWidth &&
    css`
      @media screen and (min-width: ${`${p.scaleHeight}px`}) {
        &::before,
        &:after {
          display: none;
        }

        min-height: ${`${p.scaleHeight}px`}};
      }
    `}

  ${(p) =>
    p.fullHeight &&
    css`
      min-height: 100%;
    `}
`

export default ScaledElement
