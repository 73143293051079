import React from "react";
import styled, { css } from "styled-components";

const ScaledImage = ({
  children,
  scaleHeight,
  scaleWidth,
  bg,
  objectFit,
  objectPosition,
}) => {
  const computeScale = () => {
    const perc = ((scaleHeight / scaleWidth) * 100).toFixed(3);
    return perc + "%";
  };
  return (
    <Placeholder
      ratio={computeScale}
      bg={bg}
      objectFit={objectFit}
      objectPosition={objectPosition}
    >
      {children}
    </Placeholder>
  );
};

const Placeholder = styled.div`
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-top: ${(props) => (props.ratio ? props.ratio : "50%")};
  overflow: hidden;

  ${(props) =>
    props.bg &&
    css`
      padding-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
    `}

  iframe,
  .gatsby-image-wrapper,
  picture,
  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: ${(props) =>
      props.objectFit ? props.objectFit : "cover"} !important;
    object-position: ${(props) =>
      props.objectPosition ? props.objectPosition : "center"} !important;
    height: 100%;
    width: 100%;
  }
`;

export default ScaledImage;
