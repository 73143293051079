import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Seo from "../components/Layout/Seo"
import Site from "../components/Layout/Site"
import Button from "../components/UI/Button"
import { Container, Row, Col } from "../components/UI/Grid"
import ScaledElement from "../components/UI/ScaledElement"
import ScaledImage from "../components/UI/ScaledImage"
import { Section } from "../components/UI/Section"
import { Heading, Paragraph } from "../components/UI/Typography"

const Contact = () => {
  return (
    <Site>
      <Seo title="Thank You" />
      <ScaledElement scaleWidth={1440} scaleHeight={240} fullWidth>
        <ScaledImage bg={true}>
          <StaticImage
            src="../images/banner-contact.jpg"
            alt="banner"
            layout="fullWidth"
          />
        </ScaledImage>
      </ScaledElement>
      <Section>
        <Container>
          <Row justifyContent="center" textAlign="center">
            <Col col={{ lg: 5 }}>
              <Heading as="h1" fs="h1" mb={4}>
                Thank You!
              </Heading>
              <Paragraph mb={10}>
                We have received your message and appreciate you reaching out.
                We'll get back to you soon!
              </Paragraph>

              <Button as={Link} to="/" variant="brand">
                Back to Homepage
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>
    </Site>
  )
}

export default Contact
